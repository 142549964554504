import $ from 'jquery';

$(document).ready(function () {
  // Dynamically add the "orange bar" under each H1 in inside page contents
  $('.inside_content h1, .homepage_content h3').each(function () {
    $(this).after('<div class="orange-bar"></div>');
  });

  // test for external links
  const comp = new RegExp(location.host);

  $('.inside_content a').each(function () {
    if (comp.test($(this).attr('href'))) {
      // matches
    } else {
      // external link
      $(this).addClass('external-link');
    }
  });

  // wrap all youtube iframes in a container div for responsive styling
  $('iframe[src*="youtube.com"]').each(function () {
    $(this).wrap('<div class="video-container"></div>');
  });

  // accordion code
  $('.accordion-button').on('click touch', function () {
    $(this).next('.accordion-content').slideToggle();
    $(this).toggleClass('expanded');
  });

  // Mobile nav
  $('.mobile_nav').on('click touch', function () {
    $('header nav').slideToggle();
  });
}); // end document.ready
